import { MarkerClusterer } from '@googlemaps/markerclusterer';

let map = null;
let zoomControlDiv = null;
let a15Bounds;
let a60Bounds;

const mapIcons = {
  circle:
    '<svg xmlns="http://www.w3.org/2000/svg" width="47" height="86" viewBox="0 0 47 86" fill="none"><path d="M47 23.5C47 32 41.1275 41.2475 36.5 50C33.6737 55.3457 30.926 62.4716 28.6904 68.9358C27.0503 73.6781 19.7026 73.755 17.9868 69.0396C15.6774 62.693 12.8655 55.6238 10 50C5.96292 42.0768 0 33.5 0 23.5C0 10.5213 10.5213 0 23.5 0C36.4787 0 47 10.5213 47 23.5Z" fill="#004976"/><circle cx="24" cy="23" r="10" fill="white"/></svg>',
};

const styles = [
  {
    featureType: 'administrative.land_parcel',
    elementType: 'labels',
    stylers: [
      {
        visibility: 'off',
      },
    ],
  },
  {
    featureType: 'poi',
    elementType: 'labels.text',
    stylers: [
      {
        visibility: 'off',
      },
    ],
  },
  {
    featureType: 'road.local',
    elementType: 'labels',
    stylers: [
      {
        visibility: 'off',
      },
    ],
  },
];

/**
 * initMap
 *
 * Renders a Google Map onto the selected jQuery element
 *
 * @date    22/10/19
 * @since   5.8.6
 *
 * @param   jQuery $el The jQuery element.
 * @return  object The map instance.
 */
function initMap($el) {
  const a15MinSW = new google.maps.LatLng(25.912704, 11.610856);
  const a15MinNE = new google.maps.LatLng(62.425141, 61.878318);
  a15Bounds = new google.maps.LatLngBounds(a15MinSW, a15MinNE);

  //   const a60MinSW = new google.maps.LatLng(58.629517, 5.524057);
  //   const a60MinNE = new google.maps.LatLng(59.239138, 6.376866);
  //a60Bounds = new google.maps.LatLngBounds(a60MinSW, a60MinNE);

  //   const startLocation = {
  //     lat: 30.9546197,
  //     lng: 1.6961458,
  //   };

  let markers = [];

  // Find marker elements within map.
  const $markers = $el.find('.marker');

  zoomControlDiv = $el.find('.custom-controls')[0];

  // Create gerenic map.
  const mapArgs = {
    // center: startLocation,
    zoom: 2,
    mapTypeId: google.maps.MapTypeId.ROADMAP,
    styles: styles,
    disableDefaultUI: true,
    zoomControl: true,
    fullscreenControl: true,
  };
  map = new google.maps.Map($el[0], mapArgs);

  map.fitBounds(a15Bounds);

  // Add markers.
  $markers.each(function () {
    const marker = initMarker($(this), map);
    markers.push(marker);
  });

  // Add the control to the map at a designated control position
  // by pushing it on the position's array. This code will
  // implicitly add the control to the DOM, through the Map
  // object. You should not attach the control manually.
  //   map.controls[google.maps.ControlPosition.TOP_LEFT].push(zoomControlDiv);

  // Marker clustering
  //new MarkerClusterer({ map, markers, renderer: new CustomRenderer() });

  // Return map instance.
  return map;
}

/**
 * initMarker
 *
 * Creates a marker for the given jQuery element and map.
 *
 * @date    22/10/19
 * @since   5.8.6
 *
 * @param   jQuery $el The jQuery element.
 * @param   object The map instance.
 * @return  object The marker instance.
 */
function initMarker($marker) {
  // Get position from marker.
  const lat = $marker.data('lat');
  const lng = $marker.data('lng');
  //   const type = $marker.data("type");
  const name = $marker.data('name');
  const latLng = {
    lat: parseFloat(lat),
    lng: parseFloat(lng),
  };

  let url = 'data:image/svg+xml;charset=UTF-8;base64,' + btoa(mapIcons.circle);
  let scaledSize = new google.maps.Size(30, 30);
  let anchor = null;

  const icon = {
    url: url,
    scaledSize: scaledSize, // scaled size
    optimized: false,
    anchor: anchor,
  };

  // Create marker instance.
  const marker = new google.maps.Marker({
    position: latLng,
    icon: icon,
    map: map,
  });

  // If marker contains HTML, add it to an infoWindow.
  if ($marker.html()) {
    // Create info window.
    var infowindow = new google.maps.InfoWindow({
      content: $marker.html(),
    });

    // Show info window when marker is clicked.
    google.maps.event.addListener(marker, 'click', function () {
      infowindow.open(map, marker);
    });
  }

  return marker;
}

window.initMaps = function () {
  // Render maps on page load.
  $(document).ready(function () {
    $('.acf-map').each(function () {
      initMap($(this));
    });
  });
};
