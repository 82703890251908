import Glide from "@glidejs/glide";
let glide = null;

function initImageSlider(id) {
    glide = new Glide('#' + id, {
        type: 'carousel',
        startAt: 0,
        gap: 24,
        perView: 2.1,
        breakpoints: {
            768: {
                perView: 1
            }
        }
    });

    let images = document.getElementById(id);
    if (images && images.querySelector('.glide__slides').children.length > 1) {
        glide.mount();
    }
}

export function moveGlide(whichWay) {
    glide.go(whichWay);
}

window.initImageSlider = initImageSlider;