var cururl = window.location.href;
const urlarray = cururl.split('/');
var last = urlarray[3];

// $('header .menu-item a').each(function () {
//   var pageurl = $(this).attr('href');

//   urlarray.forEach((element) => {
//     if (element == this.innerHTML) {
//       console.log(this.innerHTML, element);
//       // $(this).parent('li').addClass('current-menu-item');
//     }
//   });
// });
