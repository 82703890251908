window.addEventListener('load', function () {
  const blocks = document.querySelectorAll('.wp-block');

  if (blocks) {
    setTimeout(() => {
      blocks.forEach((block) => {
        console.log(block);
        const links = block.querySelectorAll('a');
        links.forEach((link) => {
          console.log(link);
          link.href = 'javascript:void(0)';
        });
      });
    }, 5000);
  }
});
