import Glide from "@glidejs/glide";
let glide = null;

const clamp = (val, min, max) => Math.min(Math.max(val, min), max);
function initSlider(id) {
    glide = new Glide('#' + id, {
        type: 'carousel',
        startAt: 0,
        gap: clamp(15, (3.2 + 0.03 * window.innerWidth), 30),
        perView: 1,
        peek: 60,
        breakpoints: {
            768: {
                peek: 32,
            },
            900: {
                peek: 48,
            }
        }
    });
    
    glide.mount();
}

export function moveGlide(whichWay) {
    glide.go(whichWay);
}

window.initSlider = initSlider;