let archiveProduct = document.querySelector('.post-type-archive-product');

if (archiveProduct) {
  let categories = archiveProduct.querySelectorAll('.product-category');
  let products = archiveProduct.querySelectorAll('.tease-product');
  let previousCategories = archiveProduct.querySelector(
    '.product-category.active'
  );
  const pathArray = window.location.href.split('#');
  const urlCategory = pathArray[1];

  if (urlCategory) {
    categories.forEach((category) => {
      filterProduct(products, urlCategory);

      if (urlCategory == category.dataset.filter) {
        category.classList.add('active');

        if (!previousCategories) {
          previousCategories = category;
        } else if (previousCategories != category) {
          previousCategories.classList.remove('active');
          previousCategories = category;
        }
      }
    });
  }
  categories.forEach((category) => {
    category.addEventListener('click', () => {
      filterProduct(products, category.dataset.filter);
      category.classList.add('active');

      if (!previousCategories) {
        previousCategories = category;
      } else if (previousCategories != category) {
        previousCategories.classList.remove('active');
        previousCategories = category;
      }
    });
  });
}

function filterProduct(products, filter) {
  products.forEach((product) => {
    if (product.classList.contains('active')) {
      product.classList.remove('active');
    }
    if (filter == 'all') {
      product.classList.add('active');
    } else if (product.dataset.filter == filter) {
      product.classList.add('active');
    }
  });
}
