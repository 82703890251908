import './_body-view-height';
import './_body-view-width';
import './_header-height';
import './header';
import './_search';
import './_block-map.js';
import './_scroll-to.js';
import './_active-style.js';
import './_lazy-image.js';
//import './_slideshow.js';

let skipHeader = document.querySelector('a.visually-hidden.skip-to-main');
if (skipHeader != undefined) {
  skipHeader.addEventListener('focusin', function (e) {
    this.classList.add('focused');
  });
  skipHeader.addEventListener('focusout', function (e) {
    this.classList.remove('focused');
  });
}

function getPosts() {
  let data = new FormData();
  data.append('action', 'get_posts');

  $.ajax({
    url: project_scripts.ajax_url,
    type: 'POST',
    data: data,
    cache: false,
    processData: false,
    contentType: false,
    error: function (data) {
      console.error(data);
    },
    complete: function (data) {
      console.log(data.responseText);
    },
  });
}

//getPosts();
import './_slider-configuration.js';
import './_image-slider.js';
import './_archive-product.js';
import './_disbable-links.js';
